import { useSearchParams } from '@remix-run/react'
import { useEffect, useState } from 'react'

export default function useIsEmbed() {
  const [searchParams] = useSearchParams()
  const [isEmbed, setIsEmbed] = useState(
    searchParams.get('embed') ? true : false
  )
  useEffect(() => {
    try {
      setIsEmbed(window.self !== window.top)
    } catch (e) {
      setIsEmbed(true)
    }
  }, [])
  return isEmbed
}
